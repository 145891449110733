<template>
  <div class="disbursement-wrapper">
    <tabs :tabs-list="tabs" default-select="Pending" @handleEvent="searchParam.status = $event"></tabs>
    <div class="bg-white">
      <vs-row class="items-start">
        <vs-col vs-align="center" vs-justify="center" vs-type="flex" vs-w="12">
          <div class="w-full">
            <div class="ds-wrapper" v-if="!isDisbursed">
              <div class="py-6 px-5 bg-neutral-50 flex justify-between items-center rounded-tr tab-custom">
                <div class="flex">
                  <h4 class="text-body">Total selected</h4>
                  <h3 class="font-medium text-primary-600 ml-6">{{ moneyFormat(totalSelected) }}</h3>
                </div>
                <div v-if="hasPermission">
                  <vs-button flat v-round class="flex items-center ml-auto" :disabled="checkedTransactions.length == 0" @click="checkDisbursement">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.11111 6.07111V9.77778H8.88889V6.07111L10.3022 7.47556L11.5556 6.22222L8 2.66667L4.44444 6.22222L5.69778 7.47556L7.11111 6.07111Z"
                        fill="#F7F7F7"
                      />
                      <path
                        d="M14.2222 0H1.77778C0.8 0 0 0.8 0 1.77778V14.2222C0 15.2 0.8 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0ZM14.2222 14.2222H1.77778V11.5556H4.46222C5.27111 12.6311 6.55111 13.3333 8 13.3333C9.44889 13.3333 10.72 12.6311 11.5378 11.5556H14.2222V14.2222ZM14.2222 9.77778H10.5067C10.1422 10.8089 9.16444 11.5556 8 11.5556C6.83556 11.5556 5.86667 10.8089 5.49333 9.77778H1.77778V1.77778H14.2222V9.77778Z"
                        fill="#F7F7F7"
                      />
                    </svg>
                    <span class="ml-2">Disburse selected</span>
                  </vs-button>
                </div>
              </div>
            </div>
            <vs-table :data="disbursements" class="disbursement-table stripes" :class="isDisbursed ? 'tab-custom' : ''">
              <template slot="thead">
                <vs-th width="7%" v-if="!isDisbursed">
                  <vs-checkbox class="float-left" v-model="allTransactionsSelected" :vs-value="true" @change="selectAllTransactions()"></vs-checkbox>
                </vs-th>

                <vs-th width="10%" v-if="!isDisbursed">
                  <div>
                    <div class="combo-box" @click="changeFilter('date')">
                      <label class="m-0 p-0 text-base font-medium">Disburse on</label>
                      <div class="icon-combo" v-if="!filtersHidden">
                        <vs-icon :class="(searchParam.sortBy === 'date' && searchParam.sortDir === 'asc') ? 'dark' : ''" icon="arrow_drop_up" />
                        <vs-icon :class="(searchParam.sortBy === 'date' && searchParam.sortDir === 'desc') ? 'dark' : ''" icon="arrow_drop_down" />
                      </div>
                    </div>
                    <date-picker
                      v-bind:class="{ active: searchParam.date }"
                      @change="onChangeInput()"
                      valueType="format"
                      v-if="!filtersHidden"
                      v-model="searchParam.date"
                      lang="en"
                      type="date"
                      placeholder="DD/MM/YYYY"
                      class="pay-date"
                      format="DD/MM/YYYY"
                    ></date-picker>
                  </div>
                </vs-th>

                <vs-th width="10%" v-else>
                  <div>
                    <div class="combo-box" @click="changeFilter('dateFinalised')">
                      <label>Disbursed</label>
                      <div class="icon-combo" v-if="!filtersHidden">
                        <vs-icon :class="(searchParam.sortBy === 'dateFinalised' && searchParam.sortDir === 'asc') ? 'dark' : ''" icon="arrow_drop_up" />
                        <vs-icon :class="(searchParam.sortBy === 'dateFinalised' && searchParam.sortDir === 'desc') ? 'dark' : ''" icon="arrow_drop_down" />
                      </div>
                    </div>
                    <date-picker
                      v-bind:class="{ active: searchParam.dateFinalised }"
                      @change="onChangeInput()"
                      valueType="format"
                      v-if="!filtersHidden"
                      v-model="searchParam.dateFinalised"
                      lang="en"
                      type="date"
                      placeholder="DD/MM/YYYY"
                      class="pay-date"
                      format="DD/MM/YYYY"
                    ></date-picker>
                  </div>
                </vs-th>

                <vs-th width="12%">
                  <div class="boxBody">
                    <div class="combo-box" @click="changeFilter('paymentRequestId')">
                      <label class="m-0 p-0 text-base font-medium">ID</label>
                      <div class="icon-combo" v-if="!filtersHidden">
                        <vs-icon :class="(searchParam.sortBy === 'paymentRequestId' && searchParam.sortDir === 'asc') ? 'dark' : ''" icon="arrow_drop_up" />
                        <vs-icon :class="(searchParam.sortBy === 'paymentRequestId' && searchParam.sortDir === 'desc') ? 'dark' : ''" icon="arrow_drop_down" />
                      </div>
                    </div>
                    <span @click="resetInput($event, 'paymentRequestId')" v-if="searchParam.paymentRequestId" class="iconClose">X</span>
                    <vs-input
                      v-if="!filtersHidden"
                      class="w-auto"
                      v-model="searchParam.paymentRequestId"
                      v-bind:class="{ isFocus: searchParam.paymentRequestId, textBlue: searchParam.paymentRequestId }"
                      @change="onChangeInput($event)"
                    />
                  </div>
                </vs-th>

                <vs-th width="15%">
                  <div class="boxBody">
                    <div class="combo-box" @click="changeFilter('merchantId')">
                      <label class="m-0 p-0 text-base font-medium">Disburse to</label>
                      <div class="icon-combo" v-if="!filtersHidden">
                        <vs-icon :class="(searchParam.sortBy === 'merchantId' && searchParam.sortDir === 'asc') ? 'dark' : ''" icon="arrow_drop_up" />
                        <vs-icon :class="(searchParam.sortBy === 'merchantId' && searchParam.sortDir === 'desc') ? 'dark' : ''" icon="arrow_drop_down" />
                      </div>
                    </div>
                    <span @click="resetInput($event, 'merchantId')" v-if="searchParam.merchantId" class="iconClose">X</span>
                    <vs-input
                      v-if="!filtersHidden"
                      class="w-auto"
                      v-model="searchParam.merchantId"
                      v-bind:class="{ isFocus: searchParam.merchantId, textBlue: searchParam.merchantId }"
                      @change="onChangeInput($event)"
                    />
                  </div>
                </vs-th>

                <vs-th width="20%">
                  <div class="boxBody">
                    <div class="combo-box">
                      <label class="m-0 p-0 text-base font-medium">Bank account</label>
                    </div>
                  </div>
                </vs-th>

                <vs-th width="12%">
                  <div class="boxBody">
                    <div class="combo-box" @click="changeFilter('product')">
                      <label class="m-0 p-0 text-base font-medium">Product</label>
                      <div class="icon-combo" v-if="!filtersHidden">
                        <vs-icon :class="(searchParam.sortBy === 'product' && searchParam.sortDir === 'asc') ? 'dark' : ''" icon="arrow_drop_up" />
                        <vs-icon :class="(searchParam.sortBy === 'product' && searchParam.sortDir === 'desc') ? 'dark' : ''" icon="arrow_drop_down" />
                      </div>
                    </div>
                    <vs-select v-if="!filtersHidden" v-model="searchParam.product" :multiple="true" width="200px" class="mb-0" v-bind:class="{ isFocus: searchParam.product, textBlue: searchParam.product}">
                    <vs-select-item
                      v-for="(item, index) in products"
                      :key="index"
                      :text="item.productName"
                      :value="item.customPlanId"
                      @click.native="checkProduct(item.customPlanId)"
                    />
                  </vs-select>
                  </div>
                </vs-th>

                <vs-th width="10%">
                  <div class="boxBody">
                    <div class="combo-box" @click="changeFilter('amount')">
                      <label class="m-0 p-0 text-base font-medium">Amount</label>
                      <div class="icon-combo" v-if="!filtersHidden">
                        <vs-icon :class="(searchParam.sortBy === 'amount' && searchParam.sortDir === 'asc') ? 'dark' : ''" icon="arrow_drop_up" />
                        <vs-icon :class="(searchParam.sortBy === 'amount' && searchParam.sortDir === 'desc') ? 'dark' : ''" icon="arrow_drop_down" />
                      </div>
                    </div>
                    <span @click="resetInput($event, 'amount')" v-if="searchParam.amount" class="iconClose">X</span>
                    <vs-input
                      v-if="!filtersHidden"
                      class="w-auto"
                      v-model="searchParam.amount"
                      v-bind:class="{ isFocus: searchParam.amount, textBlue: searchParam.amount }"
                      @change="onChangeInput($event)"
                    />
                  </div>
                </vs-th>

                <vs-th width="10%">
                  <div class="boxBody">
                    <div class="combo-box">
                      <label class="m-0 p-0 text-base font-medium">Status</label>
                    </div>
                  </div>
                </vs-th>

                <vs-th width="10%" v-if="searchParam.status == 'Failed'"></vs-th>

                <vs-th class="filter-cross" :class="{ toggle: !filtersHidden }">
                  <filter-icon size="1.5x" :fill="filterIconColor.fill" :stroke="filterIconColor.stroke" @click="toggleFilter"></filter-icon>
                </vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr v-for="(tr, indextr) in data" :key="indextr">
                  <vs-td :data="tr" v-if="!isDisbursed">
                    <vs-checkbox class="float-left" v-model="checkedTransactions" :vs-value="tr" @change="checkSelectedTransactions" :disabled="isQueued(tr)"></vs-checkbox>
                  </vs-td>

                  <vs-td :data="tr.date" v-if="!isDisbursed">{{ tr.date }}</vs-td>

                  <vs-td :data="tr.dateFinalised" v-else>{{ tr.dateFinalised }}</vs-td>

                  <vs-td class="whitespace-nowrap" :data="tr.paymentRequestDetail">
                    <router-link :to="{ name: 'staff-payment-requests-detail', params: { id: tr.paymentRequestDetail._id } }" target="_blank" class="underline cursor-pointer">
                      <span>{{ tr.paymentRequestDetail.paymentRequestId }}</span><br />
                    </router-link>
                    <p class="text-xs mt-1">{{ tr.flipTxId }}</p>
                  </vs-td>

                  <vs-td :data="tr.merchantDetail">
                    <span v-if="isExternal(tr)">External</span>
                    <span v-else>
                      <span>{{ tr.merchantDetail ? getMerchantName(tr.merchantDetail) : "" }}</span><br />
                      <span class="text-xs">{{ tr.merchantDetail ? tr.merchantDetail.merchantId : "" }}</span>
                    </span>
                  </vs-td>

                  <vs-td :data="tr.bankDetail">
                    <span>{{ tr.bankDetail ? tr.bankDetail.accountName : "" }}<br></span>
                    <span>BSB: {{ tr.bankDetail ? maskBsb(tr.bankDetail.bsb) : "" }}<br></span>
                    <span>Acc: {{ tr.bankDetail ? tr.bankDetail.accountNumber : "" }}<br></span>
                  </vs-td>

                  <vs-td :data="tr.productName">
                    <span>{{ tr.productName ? tr.productName : "Pay Now" }}</span><br>
                    <span v-if="tr.productId">{{ tr.productId }}</span><br>
                  </vs-td>

                  <vs-td :data="tr.formattedAmount">{{ tr.formattedAmount }}</vs-td>

                  <vs-td :data="tr.status" class="ds-wrapper">
                    <vs-chip :color="changeStatusColor(tr.status)" size="x-small" :variant="getVariant(tr.status)">{{ tr.status }}</vs-chip>
                  </vs-td>

                  <vs-td>
                    <router-link :to="{ name: 'disbursement-payload', params: { id: tr._id } }" target="_blank" class="underline cursor-pointer" v-if="searchParam.status == 'Failed'">
                      Payloads
                    </router-link>
                  </vs-td>

                  <vs-td></vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vs-col>
      </vs-row>
    </div>

    <custom-popup :popup-active="popUpActive" :title="popUpTitle" :description="popUpDescription" :buttonText="buttonText" :icon="popUpIcon" :hasCancel="hasCancel" :hasConfirm="hasConfirm" @handleClick="disburseActions"></custom-popup>
    <div class="ds-wrapper">
      <div class="rounded-b-4xl bg-white py-4 px-5">
        <vs-row class="vs-row justify-between items-center">
          <div class="records-per-page flex items-center">
            <p class="w-full mb-0 para-def-light-anc text-body mr-4 whitespace-no-wrap">Records per page</p>
            <vs-select v-model="searchParam.limit" class="per-pg">
              <vs-select-item v-for="(item, index) in limitValue" :key="index" :text="item.text" :value="item.value" />
            </vs-select>
          </div>
          <div>
            <vs-row>
              <vPegination v-model="searchParam.pageNumber" :page-count="totalPage" :total="totalPage" class="mt-0"></vPegination>
            </vs-row>
          </div>
        </vs-row>
      </div>
    </div>
  </div>
</template>

<script>
import vPegination from "@/views/components/pagination.vue";
import { mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import { FilterIcon } from "vue-feather-icons";
import Tabs from "./disbursementTabs.vue";
import CustomPopup from "@/views/components/CustomPopup.vue";
import _ from "lodash";
export default {
  components: {
    DatePicker,
    vPegination,
    FilterIcon,
    Tabs,
    CustomPopup,
  },
  name: "Disbursement",
  data() {
    return {
      filtersHidden: true,
      tabs: [
        { key: "Pending", text: "Pending" },
        { key: "Failed", text: "Error" },
        { key: "Disbursed", text: "Disbursed" },
      ],
      searchParam: {
        pageNumber: 1,
        date: "",
        dateFinalised: "",
        paymentRequestId: "",
        merchantId: "",
        amount: "",
        totalData: 0,
        limit: 50,
        pageList: ["all"],
        sortBy: "date",
        sortDir: "desc",
        status: "Pending",
        product: ""
      },
      disbursements: [],
      limit: 50,
      limitValue: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "200", value: 200 },
      ],
      checkedTransactions: [],
      allTransactionsSelected: false,
      disableDisbursement: false,
      popUpActive: false,
      popUpTitle: "",
      popUpDescription: "",
      popUpIcon: "",
      hasCancel: true,
      hasConfirm: true,
      canDisburse: false,
      products: [
        {
          productName: "All",
          customPlanId : "All"
        },
        {
          productName: "Pay Now",
          customPlanId : "Pay Now"
        }
      ],
      hasPermission: false
    };
  },
  methods: {
    ...mapActions("admin", ["getAdminUser"]),
    ...mapActions("disbursement", ["fetchDisbursementList", "fetchMonoovaBalanceDetails", "processDisbursement"]),
    ...mapActions("paymentPlans", ["fetchProducts"]),

    getMerchantName(merchant) {
      let name = "";

      if (merchant.companyName) {
        name = merchant.companyName;
      } else if (merchant.accountName) {
        name = merchant.accountName;
      } else if (merchant.fullName) {
        name = merchant.fullName;
      }

      return name;
    },

    async getUser() {
      await this.getAdminUser(this.user._id).then((result) => {
        this.hasPermission = result.data.data.permissions["disbursePaymentRequests"] || false;
      });
    },

    async getAllPayLaterProducts() {
      await this.fetchProducts().then((res) => {
        this.products.push(...res.data.data);
      });
    },

    checkProduct(val) {
      if (this.searchParam.product.includes("All") && val !== "All") {
        this.searchParam.product.splice(this.searchParam.product.indexOf("All"), 1);
      }

      if (val == "All") {
        if (this.searchParam.product.includes("All")) {
          this.selectAllProduct();
        } else {
          this.searchParam.product = [];
        }
      }
    },

    selectAllProduct() {
      const statuses = this.products.map((el) => el.customPlanId);
      this.searchParam.product = statuses;
    },

    toggleFilter() {
      this.filtersHidden = !this.filtersHidden;
      if (this.filtersHidden) {
        this.clearFilters();
      }
    },

    clearFilters() {
      this.searchParam = {
        pageNumber: 1,
        date: "",
        dateFinalised: "",
        paymentRequestId: "",
        merchantId: "",
        bankAccountId: "",
        amount: "",
        totalData: 0,
        limit: this.searchParam.limit,
        pageList: ["all"],
        product: "",
        sortBy: this.searchParam.status == "Disbursed" ? "dateFinalised" : "date",
        sortDir: "desc",
        status: this.searchParam.status,
      };
    },

    async getDisbursementList() {
      this.$vs.loading();
      const data = { searchParam: this.searchParam };
      await this.fetchDisbursementList(data.searchParam)
        .then((result) => {
          this.disbursements = result.data.data.docs;
          this.searchParam.totalData = result.data.data.pagination.total ? result.data.data.pagination.total : 0;

          if (this.checkedTransactions.length) {
            this.checkedTransactions = this.disbursements.filter((item) => {
              for (let i = 0; i < this.checkedTransactions.length; i++) {
                if (item._id == this.checkedTransactions[i]._id) {
                  return item;
                }
              }
            });
          }

          this.checkSelectedTransactions();
          this.$vs.loading.close();
        }).catch((ex) => {
          this.$vs.loading.close();
        });
    },

    changeFilter(value) {
      this.searchParam.sortBy = value;
      this.searchParam.sortDir = (this.searchParam.sortDir === "desc") ? "asc" : "desc";
    },

    changeStatusColor(status) {
      let bgClass = "";

      if (["Error"].includes(status)) {
        bgClass = "error";
      } else if (["Disbursed"].includes(status)) {
        bgClass = "success";
      } else if (["Pending"].includes(status)) {
        bgClass = "neutral";
      } else {
        bgClass = "secondary";
      }

      return bgClass;
    },

    getVariant(status) {
      let variant = "";

      if (["Pending", "Queued"].includes(status)) {
        variant = "outlined";
      }

      return variant;
    },

    resetInput(event, id) {
      this.searchParam[id] = "";
    },

    selectAllTransactions() {
      if (this.allTransactionsSelected) {
        if (this.searchParam.status == "Pending") {
          this.checkedTransactions = this.disbursements.filter((transaction) => transaction.status == "Pending");
        } else {
          this.checkedTransactions = [...this.disbursements];
        }
      } else {
        this.checkedTransactions = [];
      }
    },

    checkSelectedTransactions() {
      let filteredTransactions = this.disbursements;
      if (this.searchParam.status == "Pending") {
        filteredTransactions = filteredTransactions.filter((transaction) => transaction.status == "Pending");
      }
      this.allTransactionsSelected = (filteredTransactions.length == this.checkedTransactions.length);
    },

    moneyFormat(data, space = true) {
      let result = parseFloat(data).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");

      if (space) {
        result = `$ ${result}`;
      } else {
        result = `$${result}`;
      }

      return result;
    },

    async checkDisbursement() {
      const payload = {
        amount: this.totalSelected,
        transactions: this.checkedTransactions.map((item) => {
          return {
            _id: item._id,
            amount: item.amount,
            paymentRequestId: item.paymentRequestId,
            productId: (item.product && item.product._id) ? item.product._id : "pay-now"
          };
        })
      };

      this.$vs.loading();
      await this.fetchMonoovaBalanceDetails(payload)
        .then((result) => {
          this.canDisburse = result.data.data.canDisburse;
          if (this.canDisburse && !result.data.data.transactionWarnings.length) {
            this.popUpTitle = "Confirm disbursement";
            this.popUpDescription = `Disburse total of ${this.moneyFormat(this.totalSelected, false)}?`;
            this.popUpIcon = "warning";
            this.buttonText = "Confirm";
            this.hasCancel = true;
            this.hasConfirm = true;
          } else {
            this.popUpTitle = "Monoova account balance too low";
            this.popUpIcon = "error";
            this.buttonText = "OK";
            this.hasCancel = true;
            this.hasConfirm = result.data.data.transactionSuccess.length;
            let errorMessage = "";

            if (result.data.data.transactionWarnings && result.data.data.transactionWarnings.length) {
              result.data.data.transactionWarnings.map((item) => {
                errorMessage += `<p>mAccount (${item.accountNumber}) balance is ${this.moneyFormat(item.availableBalance, false)}, which is less than the requested disbursement amount, disbursements will not be processed.</p>`

                item.transactions.map((transactionItem) => {
                  this.checkedTransactions = this.checkedTransactions.filter((itemData) => itemData._id != transactionItem);
                });
              });
            }

            if (result.data.data.transactionSuccess && result.data.data.transactionSuccess.length) {
              errorMessage += "<br/>";
              result.data.data.transactionSuccess.map((item) => {
                errorMessage += `<p>mAccount (${item.accountNumber}) balance is ${this.moneyFormat(item.availableBalance, false)}, disbursements will proceed.</p>`
              });
            }

            this.popUpDescription = errorMessage;
          }
          this.popUpActive = true;
          this.$vs.loading.close();
        }).catch((ex) => {
          this.showToastMessage("Error", ex.message, "Error");
          this.$vs.loading.close();
        })
    },

    isQueued(transaction) {
      return (transaction.status == "Queued");
    },

    disburseActions(event) {
      switch (event) {
        case "confirm":
          switch (this.canDisburse) {
            case true:
              this.confirmDisbursement();
              break;
            case false:
              this.popUpActive = false;
              break;
          }
          break;
        case "close":
          this.popUpActive = false;
          break;
        default:
          this.popUpActive = false;
      }
    },

    async confirmDisbursement() {
      const payload = {
        transactions: this.checkedTransactions.map((item) => { return item._id })
      };

      this.$vs.loading();
      await this.processDisbursement(payload)
        .then((result) => {
          let showSuccess = true;
          let showError = false;
          let successCount = this.checkedTransactions.length;

          if (result.data.warnings.length) {
            showError = true;
            showSuccess = !(this.checkedTransactions.length == result.data.warnings.length);
            successCount = successCount - result.data.warnings.length;
          }

          this.clearPopUpData();
          this.clearFilters();
          this.disbursements = [];
          this.filtersHidden = true;
          this.checkedTransactions = [];
          this.allTransactionsSelected = false;

          if (showSuccess) {
            this.showToastMessage("Success", `${successCount} disbursements processed successfully`, "success");
          }

          if (showError) {
            this.showToastMessage("Error", `${result.data.warnings.length} disbursements could not be processed`, "error");
          }

          this.$vs.loading.close();
        }).catch((ex) => {
          this.showToastMessage("Error", ex.message, "Error");
          this.$vs.loading.close();
        });
    },

    clearPopUpData() {
      this.popUpTitle = "";
      this.popUpDescription = "";
      this.popUpIcon = "";
      this.buttonText = "";
      this.hasCancel = false;
      this.popUpActive = false;
    },

    isExternal(data) {
      return !data.paymentRequestDetail.refModel || (data.paymentRequestDetail.refModel && data.paymentRequestDetail.refModel != "Bank");
    }
  },
  mounted() {
    this.getUser();
    this.getDisbursementList();
    this.getAllPayLaterProducts();
  },
  watch: {
    "searchParam.status"(val) {
      this.disbursements = [];
      this.clearFilters();
      this.filtersHidden = true;
      this.checkedTransactions = [];
      this.allTransactionsSelected = false;
      this.getDisbursementList();
    },

    "searchParam.pageNumber"(page) {
      this.getDisbursementList();
    },

    "searchParam.date"(val) {
      this.getDisbursementList();
    },

    "searchParam.dateFinalised"(val) {
      this.getDisbursementList();
    },

    "searchParam.paymentRequestId": _.debounce(function (val) {
      this.getDisbursementList();
    }, 600),

    "searchParam.merchantId": _.debounce(function (val) {
      this.getDisbursementList();
    }, 600),

    "searchParam.bankAccountId": _.debounce(function (val) {
      this.getDisbursementList();
    }, 600),

    "searchParam.amount": _.debounce(function (val) {
      this.getDisbursementList();
    }, 600),

    "searchParam.sortDir"(val) {
      this.getDisbursementList();
    },

    "searchParam.product"(val) {
      this.getDisbursementList();
    },
  },
  computed: {
    filterIconColor() {
      if (!this.filtersHidden) {
        return { fill: "#0f67f4", stroke: "#0f67f4" };
      }

      return { fill: "white", stroke: "#828282" };
    },

    user() {
      return this.$store.state.AppActiveUser;
    },

    adminId() {
      return ["admin", "superadmin"].includes(this.user.userType.toLowerCase()) ? this.user._id : this.user.partnerId;
    },

    totalPage() {
      return Math.ceil(this.searchParam.totalData / this.searchParam.limit);
    },

    isDisbursed() {
      return (this.searchParam.status == "Disbursed");
    },

    totalSelected() {
      let sum = 0;

      this.checkedTransactions.map((transaction) => {
        sum += transaction.amount;
      });

      return sum;
    },
  },
};
</script>
