<template>
<div id="rellopay" class="ds-wrapper">
  <div class="scrollable-tabs">
    <vue-horizontal class="scrollable-tabs-container" :displacement="0.5" :button-between="false">
      <div class="tab" v-for="(tab, index) in tabsList" :key="index" @click="handleClick(tab.key)" :class="{ 'tab--selected': isSelected === tab.key }">
        <span class="tab-label-container">
          <span class="tab-label">{{ tab.text }}</span>
        </span>
      </div>
    </vue-horizontal>
  </div>
</div>
</template>

<script>
import VueHorizontal from "vue-horizontal";

export default {
  components: { VueHorizontal },
  name: "Tabs",
  props: {
    tabsList: {
      type: Array,
      required: true,
    },
    defaultSelect: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSelected: this.defaultSelect,
    };
  },
  methods: {
    handleClick(value) {
      this.isSelected = value;
      this.$emit("handleEvent", value);
    },
  },
};
</script>
