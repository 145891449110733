<template>
  <div class="ds-wrapper">
    <vs-popup :title="title" :header-icon="icon" class="close-icon-hidden" :active.sync="isPopupActive">
      <p v-html="description" class="text-sm"></p>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" action-btn @click="handleClick('confirm')" v-if="hasConfirm">{{ buttonText }}</vs-button>
        <vs-button @click="handleClick('close')" size="large" type="flat" class="ml-6 md:ml-10" v-if="hasCancel">Cancel</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  name: "CustomPopup",
  props: {
    popupActive: { required: true, type: Boolean },
    title: { required: true, type: String },
    description: { type: String },
    hasCancel: { type: Boolean, default: true },
    hasConfirm: { type: Boolean, default: true },
    icon: { type: String, default: "warning" },
    buttonText: { type: String, default: "Confirm" },
  },
  computed: {
    isPopupActive: {
      get: function () {
        return this.popupActive;
      },
      set: function (newValue) {
        this.handleClick("close");
      }
    }
  },
  methods: {
    handleClick(value) {
      this.$emit("handleClick", value);
      return;
    }
  },
  mounted() {
    document.body.classList.add("custom-popup");
  }
}
</script>